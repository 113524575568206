header {
    text-align: center;
    font-size: 1.5rem;

    h1 {
        font-family: "CambonLight", serif;
        font-size: 4rem;
        text-transform: uppercase;
        margin-bottom: 1rem;
        @media screen and (min-width: 992px) { font-size: 5rem; }
    }

    h2 {
        font-size: 1.9rem;
        margin-bottom: 0.5rem;
    }

    .logo {
        max-width: 181px;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    p {
        text-align: left;
        font-size: 1.1rem;
        line-height: 1.25rem;

        em {
            font-style: italic;
        }
    }

    .underlinedLink {
        color: initial;
        position: relative;
        text-decoration: none;
        
        &::after {
            position: absolute;
            content: "";
            bottom: 2px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: rgba(0,0,0, 1);
            transition: width linear 175ms;
        }

        &:hover,
        &:focus {
            &::after {
                width: 15px;
            }
        }
    }
}

/* Schedules */
.schedules {
    background-color: #E4003B;
    color: white;
    padding: 2rem 0 0 0;
    margin-top: 2rem;
    margin-bottom: 2rem;
    @media screen and (min-width: 1440px) { padding: 2rem 0; }
}

.schedules-grid {
    display: grid;
    grid-template-rows: 1fr;
    column-gap: 6rem;
    justify-items: center;

    @media screen and (min-width: 768px) { grid-template-columns: repeat(2, 1fr); }
    @media screen and (min-width: 1024px) { grid-template-columns: repeat(3, 1fr); }
    @media screen and (min-width: 1440px) { grid-template-columns: repeat(5, 1fr); }
}
