.dropRow__title {
    font-family: "ABCFavoritMono", serif;
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 1.8rem;

    .drop__endsIn,
    .startTimeText {
        font-size: 1.5rem;
        text-transform: uppercase;
    }
}

.dropRow {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 3.4rem;
    margin-bottom: 6rem;
    position:relative;

    @media screen and (min-width: 768px) { grid-template-columns: repeat(2, 1fr); }
    @media screen and (min-width: 1440px) { grid-template-columns: repeat(4, 1fr); }

    &.inactive::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: white, $alpha: .7);
    }
}
