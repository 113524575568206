.wrapper {
    width: 90%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
}

.fullPageHeight {
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    padding-top: 2rem;
    @media screen and (min-width: 992px) {
        padding-top: initial;
    }
}

.numberFont { font-family: "ABCFavoritMono", serif; }
.normalFont { font-family: "CambonLight", serif; }
.normalFontBold { font-family: "CambonBook", serif; }
.upperCase { text-transform: uppercase; }
