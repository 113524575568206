.dropRowArticle {
    min-height: 517px;
    padding: 6px;
    border: 2px solid transparent;
    transition: border-color linear 125ms;
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;

    // &:not(.dummyItem):hover { border-color: #E4003B; }

    &.pointer { cursor: pointer; }

    &--active:hover { border-color: #E4003B; }

    &--inactive {
        position: relative;
        &::after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.7);
        }
        &.pointer {
            cursor: pointer;
            &::after { content: none; }
            &:hover {
                border-color: #E4003B;
                &::before {
                    position: absolute;
                    content: " preview ";
                    background-color: #E4003B;
                    color: white;
                    top: 0;
                    right: 0;
                    z-index: 20;
                    padding: 0.5rem 1rem;
                }
            }
        }
    }

    img {
        object-fit: cover;
        object-position: center center;
    }

    h2 {
        font: italic normal 300 34px/38px Cambon;
        letter-spacing: 0px;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    h3 {
        font: normal normal normal 24px/32px Cambon;
        letter-spacing: 0px;
    }

    .bottom {
        margin-top: auto;

        a {
            font: normal normal 300 24px/32px Cambon;
            letter-spacing: 0px;
            text-decoration: none;
            color: black;
            transition: color linear 125ms;

            &:hover,
            &:active,
            &:focus { color: #E4003B; }
        }

        span:not(.bidStartsAt) {
            float: right;
            font: normal normal normal 24px/32px ABCFavoritMono;
            letter-spacing: 0px;
            position: relative;

            &.closed {
                float: initial;
                a { color: #E4003B; }
            }

            .bidStartsAt {
                position: absolute;
                top: -20px;
                right: 0;
                font-size: 0.68rem;
            }
        }
    }
}
