#menuToggle {
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba($color: white, $alpha: 0.7);
  padding: 10px;
  border: none;
  @media screen and (min-width: 992px) {
    display: none;
  }
}

#menuToggle span {
  display: block;
  width: 20px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: black;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle.open span {
  opacity: 1;
  transform: rotate(45deg) translate(-5px, -10px);
}

/*
 * But let's hide the middle one.
 */
#menuToggle.open span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle.open span:nth-last-child(2) {
  transform: rotate(-45deg) translate(-2px, 8px);
}



nav ul {
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;

  margin-top: -12rem;
  transition: margin 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

  &.open {
    margin-top: -1rem;
  }

  @media screen and (min-width: 992px) {
    margin-top: initial;
    &.open {
      margin-top: initial;
    }
  }

  li {
    line-height: 2rem;

    a {
      text-decoration: none;
      text-transform: uppercase;
      font-family: "CambonBook", serif;
      color: #000;
      font-size: 1.5rem;

      &.active {
        color: #E4003B;
      }
    }
  }

  // responsive
  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    margin-top: initial;
  
    li { line-height: 6rem; text-align: left; }
    li:nth-child(3) { text-align: right; }
    li:nth-child(4) { text-align: right; }
  }
}
