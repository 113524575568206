.newsletter__page {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6rem;

    h2 {
        font-size: 4rem;
        line-height: 4rem;
        text-transform: uppercase;
        margin-top: 3.6rem;
        margin-bottom: 4rem;
        @media screen and (min-width: 992px) {
            font-size: 7.8rem;
            line-height: 10rem;
            margin-bottom: 7.6rem;
        }
    }
    
    p {
        font-size: 1.7rem;
        line-height: 1.7rem;
        text-transform: uppercase;
        margin-bottom: 3rem;
        @media screen and (min-width: 992px) {
            font-size: 3.75rem;
            line-height: 3.75rem;
        }
    }
}

.newsletter-form__container {
    width: 100%;
    max-width: 787px;
    font-family: "CambonLight", serif;
    font-size: 1.12rem;

    input:not([type="checkbox"]) {
        width: 85%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border: 1px solid black;
        float: left;
        box-sizing: border-box;
        font-family: "CambonBook", serif;
        font-size: 1.12rem;
        outline: none;
    }
    
    button {
        width: 15%;
        background-color: #E4003B;
        height: 50px;
        line-height: 50px;
        color: white;
        border: 1px solid transparent;
        text-transform: uppercase;
        cursor: pointer;
        transition: background linear 135ms;
        outline: none;

        &:hover,
        &:active,
        &:focus { background-color: #E4003B; }

        &:disabled {
            cursor: default;
            appearance: auto;
            background-color: black;
            border-color: black;
        }
    }

    .agree__container {
        margin-top: 1rem;
        text-align: left;
    }

    input[type="checkbox"] {
        width: 20px;
        height: 20px;
        cursor: pointer;
        vertical-align: -2px;
        border: 1px solid;
        margin-right: 1rem;
        
        &:checked,
        &:checked:hover,
        &:focus {
            border-color: black;
            outline-color: transparent;
        }
    }

    .message {
        margin-top: 0.5rem;
        width: 85%;
        font-style: italic;
        color: transparent;
        font-size: 0.85rem;

        &.error { color: #E4003B; }
        &.success { color: black; }
    }
}
