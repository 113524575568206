.schedules-grid {
    article {
        margin-bottom: 3rem;
        @media screen and (min-width: 1440px) { margin-bottom: initial; }

        header {
            display: grid;
            grid-template-columns: 50px 1fr;
            gap: 0.5rem;
            font-size: 1.12rem;
            text-align: left;
            margin-bottom: 1rem;

            span {
                font-family: "ABCFavoritMono", serif;
                font-size: 2rem;
            }

            div:first-child { border-right: 1px solid white; }
        }

        ul {
            li {
                font-family: "CambonBook", serif;
                font-size: 1.25rem;
                line-height: 1.68rem;
                text-transform: uppercase;
            }
        }
    }
}
