.timmer__newsletter {
    z-index: 10;
    grid-column: 1 / 1;
    @media screen and (min-width: 768px) { grid-column: 1 / 3; }
    @media screen and (min-width: 1440px) { grid-column: 1 / 5; }
}

.timmer__newsletter__inside {
    background-color: white;
    padding: 5rem;
    text-align: center;
    text-transform: uppercase;
    font-family: "ABCFavoritMono", serif;
    border: 1px solid black;
    font-size: 3rem;

    .newsletterContainer {
        font-size: initial;
        margin-top: 3rem;
        font-size: 1.5rem;
        font-family: "CambonLight", serif;
        text-transform: initial;
        font-weight: normal;

        h5 {
            font-family: "CambonBook", serif;
            font-size: 1.5rem;
            line-height: 2rem;
            margin-bottom: 1rem;
        }

        .newsletter-form__container {
            width: 100%;
            max-width: none;
        }

        input:not([type="checkbox"]) {
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            border: 1px solid black;
            box-sizing: border-box;
            font-family: "CambonBook", serif;
            font-size: 1.12rem;
            outline: none;
            
            @media screen and (min-width: 667px) {
                width: 85%;
                float: left;
            }
        }
        
        button {
            width: 100%;
            background-color: #E4003B;
            height: 50px;
            line-height: 50px;
            color: white;
            border: 1px solid transparent;
            text-transform: uppercase;
            cursor: pointer;
            transition: background linear 135ms;
            outline: none;

            @media screen and (min-width: 667px) { width: 15%; }
        
            &:hover,
            &:active,
            &:focus { background-color: #E4003B; }
        
            &:disabled {
                cursor: default;
                appearance: auto;
                background-color: black;
                border-color: black;
            }
        }

        .message {
            margin-top: 0.5rem;
            width: 100%;
            font-style: italic;
            color: transparent;
            font-size: 0.85rem;

            @media screen and (min-width: 667px) { width: 85%; }
        }
    }
}
