.howDowsItWork__page {
    margin-bottom: 4.5rem;
    
    h2 {
        font-family: "CambonCambonLight", serif;
        font-weight: 300;
        font-size: 3.75rem;
        line-height: 3.75rem;
        letter-spacing: 0px;
        color: #000000;
        margin-bottom: 0.2rem;

        &.pageTitle {
            font-size: 5rem;
            line-height: 5rem;
            text-transform: uppercase;
            margin-bottom: 2.5rem;
        }
    }

    p {
        font-size: 1.5rem; 
        line-height: 2rem;

        & + h2 {
            margin-top: 4.5rem;     
        }

        a {
            color: initial;
            position: relative;
            text-decoration: none;
            
            &::after {
                position: absolute;
                content: "";
                bottom: 2px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: rgba(0,0,0, 1);
                transition: width linear 175ms;
            }

            &:hover,
            &:focus {
                &::after {
                    width: 15px;
                }
            }
        }
    }

    ol {
        list-style: decimal;
        margin: 0 0 0 2rem;
        padding: 0;
        li {
            width: auto;
            padding-left: 0.5rem;
        }
    }
}
