footer {
    background-color: black;
     color: white;
     margin-top: 6rem;
     padding: 2rem 0;
     margin-top: auto;

     .footer__grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 3rem;
        text-align: center;

        @media screen and (min-width: 992px) {
            grid-template-columns: repeat(4, 1fr);
            text-align: initial;
        }
    }

    a {
        color: white;
    }

    li {
        display: inline-block;
        margin-right: 2rem;
        margin-top: .5rem;
    }
}

.ephemeral_footer_logo {
    width: 233px;
    height: auto;
}
